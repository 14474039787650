.post {
  display: flex;
  flex-wrap: nowrap;
  vertical-align: middle;
  align-items: flex-start;
  font-weight: 500;
  margin: 5px 0;

  .myicon {
    margin-right: 10px;
  }

  .link {
    line-height: 1.3;

    a {
      color: #404040;
    }

    a:visited {
      color: #808080;
    }

    a:hover {
      color: #000000;
      text-decoration: underline;
    }

    small {
      display: block;
      font-size: 0.75em;
    }
  }
}
